import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" keywords={[`gatsby`, `application`, `react`]} />
    <div style={{ maxWidth: `100px`, marginBottom: `1.45rem` }}>
      <Image />
    </div>
	<h2>Qualität ohne Kompromisse.</h2>
	<h4>Mein Angebot für Sie: PCs, Laptops und Service auf höchstem Niveau</h4> 
	<p>Beratung</p> <ul> <li>Die Lösung&nbsp;für Ihr persönliches Computerproblem! 
	</li><li>Unabhängige und kompetente Beratung </li></ul> 
	<p>Maßgefertigte PCs und Notebooks</p> <ul> <li>Effizient, Schnell, Zuverlässig und Leise </li>
	<li>Kompromisslose Qualität </li><li>Bestes Preis/Leistungsverhältnis </li>
	<li>Lieferung mit Installations- und Testprotokoll </li><li>Service und Hilfe</li></ul> 
	<p>Service und Reparatur</p> <ul> <li>Aufrüstung </li><li>Neuinstallation </li><li>Virenschutz und Virenbeseitigung </li>
	<li>Datenrettung, Datensicherung </li><li>E-Mail einrichten, Homepages erstellen</li></ul>

    <Link to="/impressum/">Impressum</Link>
  </Layout>
)

export default IndexPage
